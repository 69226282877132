@keyframes navMotion {
  from {
    transform: translateY(20px);
    opacity: 0;
  }

  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

@media (min-width: 978px) {
  .nav-container {
    position: sticky;
    transition: top 0.4s ease-out;
    z-index: 20;
    margin: 0 auto;
    background: #030729;
    color: white;
    margin-bottom: -2px;
    &.light {
      background: white;
      color: black;
      .button.secondary {
        color: #f26722 !important;
      }
    }
    .wrapper {
      padding: 25px 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 auto;
      justify-content: space-between;

      .nav-center {
        display: flex;
        font-family: "Plus Jakarta Sans";
        position: relative;

        .n-c-item {
          margin-right: 45px;

          &:last-child {
            margin-right: 0px;
          }

          &:hover {
            .n-c-i-el {
              color: #f26722;
              path {
                fill: #f26722;
              }
            }

            .n-c-i-dropdown {
              display: block !important;
            }
          }

          .n-c-i-el {
            display: flex;
            align-items: center;
            cursor: pointer;
            font-weight: 600;

            svg {
              margin-left: 8px;
              transform: translateY(1px);
            }
          }

          .n-c-i-dropdown {
            display: none;
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            padding-top: 10px;
            z-index: 1;

            .d-inner-wrapper {
              background: #ffffff;
              border-radius: 10px;
              min-width: 651px;
              padding: 35px;
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              border: 1px solid rgba(228, 226, 226, 0.79);
              box-shadow:
                0px 0px 1px rgba(0, 0, 4, 0.12),
                0px 16px 24px rgba(3, 2, 7, 0.12);

              &.smaller {
                min-width: 447px;
              }

              @for $i from 1 through 10 {
                $delay: $i * calc(1s / 14);
                .d-r-item:nth-child(#{$i}) {
                  animation: navMotion 0.2s + 0.1 $delay forwards ease-out;
                }
              }

              .d-r-item {
                width: calc(50% - 12px);
                display: flex;
                flex-direction: row;
                margin-bottom: 25px;

                &:nth-last-of-type(-n + 2) {
                  margin-bottom: 0px;
                }

                .i-right {
                  margin-left: 17px;

                  .i-r-title {
                    color: #171717;
                    font-weight: 700;
                    margin-bottom: 4px;
                  }

                  .i-r-desc {
                    color: #71717a;
                    font-size: 13px;
                    line-height: 17px;
                  }
                }
              }
            }
          }
        }
      }

      .nav-btns {
        display: flex;
        flex-direction: row;
        align-items: center;
        div:nth-child(1) {
          margin-right: 28px;
        }
        div:nth-child(2) {
          margin-right: 0px;
        }
        .button {
          min-width: 125px;
        }
      }

      .hamburger-menu,
      .mobile-menu {
        display: none;
      }
    }
  }
}

@media (max-width: 978px) {
  .nav-container {
    position: sticky;
    width: 100%;
    transition: top 0.4s ease-out;
    z-index: 20;
    color: black;
    background: #030729;
    margin-bottom: -2px;
    &.light {
      background: white;
      .hamburger {
        & span {
          background-color: #565869 !important;
        }
      }
    }
    &.show-modal {
      height: 100vh;
      overflow-y: hidden;
      position: relative;
    }

    .wrapper {
      padding: 20px;
      display: flex;
      flex-direction: row;
      max-width: 1500px;
      margin: 0 auto;
      align-items: center;
      justify-content: space-between;

      .nav-links,
      .social-links,
      .nav-btns,
      .nav-center {
        display: none;
      }

      .logo > svg {
        height: 32px;
        width: auto;
        transform: translateY(2px);
      }

      .hamburger-menu {
        width: 30px;
        height: 40px;
        z-index: 10;
        -webkit-tap-highlight-color: transparent;
        cursor: pointer;
        .hamburger {
          margin: 10px auto;
          position: relative;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: 0.5s ease-in-out;
          -moz-transition: 0.5s ease-in-out;
          -o-transition: 0.5s ease-in-out;
          transition: 0.5s ease-in-out;

          & span {
            display: block;
            position: absolute;
            height: 3px;
            width: 100%;
            background-color: #c1c4ca;
            border-radius: 3px;
            opacity: 1;
            left: 0;
            -webkit-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
            -webkit-transition: 0.25s ease-in-out;
            -moz-transition: 0.25s ease-in-out;
            -o-transition: 0.25s ease-in-out;
            transition: 0.25s ease-in-out;

            &.light {
              background-color: #565869;
            }

            &:nth-child(1) {
              top: 0px;
            }
            &:nth-child(2) {
              top: 10px;
              left: 20%;
              width: 80%;
            }
            &:nth-child(3) {
              top: 20px;
            }
          }

          &.open {
            margin: 0px auto;

            span {
              background-color: #565869;
              &:nth-child(1) {
                top: 18px;
                -webkit-transform: rotate(135deg);
                -moz-transform: rotate(135deg);
                -o-transform: rotate(135deg);
                transform: rotate(135deg);
              }

              &:nth-child(2) {
                opacity: 0;
                left: -60px;
              }

              &:nth-child(3) {
                top: 18px;
                -webkit-transform: rotate(-135deg);
                -moz-transform: rotate(-135deg);
                -o-transform: rotate(-135deg);
                transform: rotate(-135deg);
              }
            }
          }
        }
      }

      .mobile-menu {
        background: white;
        opacity: 1;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 2;
        height: 100vh;
        overflow-y: hidden;
        font-family: "Plus Jakarta Sans";

        &.off {
          visibility: hidden;
        }

        .wrapper-mobile {
          position: absolute;
          top: 8%;
          width: 100%;

          &.on {
            ul {
              @for $i from 1 through 7 {
                $delay: $i * calc(1s / 14);
                li:nth-child(#{$i}) {
                  animation: navMotion 0.2s + 0.1 $delay forwards ease-out;
                }
              }
            }
          }

          ul {
            list-style: none;
            padding-inline-start: 30px;
            padding-inline-end: 30px;

            li {
              opacity: 0;
              text-align: center;
              margin-bottom: 24px;
              font-family: "Montserrat", sans-serif;

              &:nth-child(6) {
                margin-bottom: 25px;
              }

              a {
                font-size: 20px;
                font-weight: 600;
                color: #565869;
              }

              .button {
                width: 100% !important;
                max-width: 400px;
                font-size: 16px !important;

                &.secondary {
                  color: #f26722 !important;
                }
              }
            }

            .mobile-links {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              max-width: 400px;
              margin-left: auto;
              margin-right: auto;

              .m-l-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: 13px;
                color: #171717;
                font-weight: 600;

                &.active-i {
                  color: #f26722;

                  path {
                    fill: #f26722 !important;
                  }
                }

                svg {
                  transform: translateY(1px);

                  path {
                    fill: #171717;
                  }
                }
              }
            }

            .m-d-wrapper {
              display: flex;
              flex-direction: row;
              justify-content: center;

              .m-dropdown {
                margin: -15px -17px 0;
                padding: 20px 17px;
                border-radius: 20px;
                border: 1px solid rgba(228, 226, 226, 0.79);
                box-shadow:
                  0px 0px 1px rgba(0, 0, 4, 0.12),
                  0px 16px 24px rgba(3, 2, 7, 0.12);
                max-width: 400px;
                width: 100%;

                @for $i from 1 through 10 {
                  $delay: $i * calc(1s / 14);
                  .d-r-item:nth-child(#{$i}) {
                    animation: navMotion 0.5s + 0.1 $delay forwards ease-out;
                  }
                }

                .d-r-item {
                  display: flex;
                  flex-direction: row;
                  margin-bottom: 17px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  svg {
                    transform: translateY(2px);
                    height: 35px;
                    width: 35px;
                  }

                  .i-right {
                    margin-left: 10px;

                    .i-r-title {
                      color: #171717;
                      font-weight: 700;
                      font-size: 14px;
                      margin-bottom: 4px;
                    }

                    .i-r-desc {
                      color: #71717a;
                      font-size: 12px;
                      line-height: 17px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.align-center {
  align-items: center;
}

a {
  text-decoration: none;
  color: unset;
}
