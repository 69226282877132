.footer {
  background: linear-gradient(0deg, #030729, #030729), rgba(3, 7, 41, 0.92);
  position: relative;

  .vect-21 {
    position: absolute;
    right: 10%;
    bottom: 0;
    z-index: 0;

    @media (max-width: 668px) {
      right: 0px;
    }
  }

  .footer-wrapper {
    position: relative;
    z-index: 1;
    font-family: "Plus Jakarta Sans";
    padding: 0 40px;
    color: white;
    font-size: 14px;
    line-height: 24px;

    @media (max-width: 768px) {
      padding: 0 20px;
    }

    .f-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 40px 0 20px;

      @media (max-width: 768px) {
        padding: 30px 0px;
      }

      .f-info {
        display: block;
        min-width: 335px;

        @media (max-width: 668px) {
          margin-bottom: 30px;
        }

        .f-logo {
          margin-bottom: 14px;
        }

        .f-i-desc {
          margin-bottom: 15px;

          a:hover {
            color: #f26722;
          }
        }

        .f-i-title {
          color: #9e9e9e;
          font-weight: 700;
        }
      }

      .f-group {
        display: block;
        min-width: 130px;

        .f-g-title {
          font-weight: 700;
          font-size: 13px;
          color: #757575;
          margin-bottom: 40px;

          @media (max-width: 668px) {
            margin-bottom: 20px;
          }
        }

        .f-g-item {
          margin-bottom: 18px;

          &:hover {
            color: #f26722;
          }

          &:last-child {
            margin-bottom: unset;
          }
        }

        .s-items {
          display: flex;

          div {
            margin-right: 14px;

            &:last-child {
              margin-right: unset;
            }
          }
        }
      }
    }

    .divider {
      border-top: 1px solid #e4e4e7;
      width: 81%;
      margin: 0 auto;
      opacity: 0.4;

      @media (max-width: 668px) {
        width: 90%;
      }
    }

    .f-bottom {
      text-align: center;
      color: #fcfcfc;
      padding: 35px 0;

      @media (max-width: 668px) {
        padding: 25px 0;
      }
    }
  }
}
