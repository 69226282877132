.terms-page {
  font-family: "Montserrat", sans-serif;
  .terms-wrapper {
    margin: 60px auto 80px auto;
    overflow: hidden;
    padding: 0 20px;
    @media (max-width: 538px) {
      margin-bottom: 30px;
    }
  }

  .terms-intro {
    margin-bottom: 50px;
    p {
      font-size: 23px;
      @media only screen and (max-width: 768px) {
        font-size: 19px;
      }

      &.extra-21 {
        font-weight: 700;
        font-size: 33px;
        @media only screen and (max-width: 768px) {
          font-size: 28px;
        }
      }
    }
  }

  .terms_container {
    padding: 0 50px;
    @media only screen and (max-width: 768px) {
      padding: 0 15px;
    }
  }

  .terms_head {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 1rem !important;
    counter-reset: css-counters 0;
    position: relative;
  }

  .terms_head::before {
    counter-increment: css-counters;
    content: 2 !important;
    position: absolute;
    top: 0;
    left: 0;
  }

  .terms_subhead {
    font-weight: 500;
    margin-bottom: 0.5rem !important;
  }

  ul {
    margin-top: 1rem;
    &.disc {
      margin-left: 2rem;
      list-style: disc;
    }
    &.roman {
      margin-left: 2rem;
      list-style: lower-roman;
    }
    & > li {
      &:not(:last-of-type) {
        margin-bottom: 1rem;
      }
    }
  }

  .terms_body {
    font-weight: 300;
    margin-bottom: 1rem !important;
  }

  .terms_subhead,
  .terms_body {
    font-size: 16px;
    font-style: normal;
    line-height: 33px;
    letter-spacing: 0em;
    text-align: left;
  }

  div .terms_container {
    &:not(:last-of-type) {
      margin-bottom: 4.375rem;
    }
  }

  .terms_get_started {
    width: 100%;
    height: fit-content;
    padding-top: 4rem;
    padding-bottom: 4rem;
    color: white;
    background: #0056cd;
    margin-top: 4rem;

    & > div {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media only screen and (max-width: 768px) {
        display: block;
      }
      .content {
        width: 60%;
        @media only screen and (max-width: 768px) {
          width: 100%;
        }
        text-align: left;
        font-style: normal;

        h4 {
          font-size: 28px;
          font-weight: 600;
          line-height: 35px;
          letter-spacing: 0em;
        }

        p {
          font-size: 18px;
          font-weight: 300;
          line-height: 23px;
          letter-spacing: 0em;
          margin-top: 26px;
        }
      }
    }
  }

  ul.terms_list_body_contaner,
  ul.terms_body {
    list-style-type: none;
    counter-reset: terms-counters 0;
    li {
      position: relative;
      padding-left: 3rem;
    }
    & > li::before {
      position: absolute;
      left: 0rem;
      line-height: 28px;
      font-size: 22px;
      height: 22px;
      counter-increment: terms-counters;
      content: counters(terms-counters, ".");
    }
  }

  li {
    list-style-type: none;
  }

  .bold-21,
  .b21 {
    font-weight: 600;
  }

  .i21 {
    font-style: italic;
  }

  .p21 {
    padding-left: 30px;
  }
  a {
    text-decoration: underline;
  }

  .more-space::before {
    left: -0.8rem !important;
  }
}
