.landing-page-container {
  .section-title {
    color: #f26722;
    font-size: 49px;
    text-align: center;
    font-weight: 700;
    font-family: Helvetica, sans-serif;
    @media (max-width: 768px) {
      font-size: 33px;
    }
  }

  .line {
    border-top: 1px solid #d7d1d3;
    max-width: 90%;
    margin: 0 auto;
  }

  .scroll-btns {
    margin-top: 30px;
    flex-direction: row;
    justify-content: flex-end;
    display: none;
    &.show {
      display: flex;
    }
    @media (max-width: 768px) {
      margin-top: 20px;
    }
    .sc-btn {
      background-color: #f26722;
      padding: 16px;
      cursor: pointer;
      border-radius: 50%;
      transition: 0.5s all ease-in-out;
      @media (max-width: 768px) {
        padding: 13px;
      }
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        opacity: 0.8;
      }
    }
    .sb-left {
      margin-right: 15px;
    }
  }
}

.section-title {
  text-align: center;
  font-family: "Gilroy";
  font-weight: 800;
  font-size: 64px;

  @media (max-width: 768px) {
    font-size: 46px;
  }
}
