.contact-us {
  .contact-us-wrapper {
    margin: auto auto 80px auto;
    overflow: hidden;
    // padding: 0 20px;
    min-height: calc(100vh - 101px);
    @media (max-width: 538px) {
      margin-bottom: 30px;
      min-height: calc(100vh - 64px);
    }
    .contact-us-header {
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .cuh-row-one {
        color: #313239;
        font-size: 33.18px;
        line-height: 41px;
        font-weight: 700;
        max-width: 400px;
      }

      .cuh-row-two {
        margin-top: 40px;
        color: #565869;
        font-size: 20px;
        font-weight: 400;
        max-width: 686px;
        line-height: 32px;
        @media (max-width: 538px) {
          font-size: 18px;
        }

        a {
          font-weight: 600;
          color: #f36622;
        }
      }
    }

    .form-content {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      form {
        width: 100%;
        ::-webkit-input-placeholder {
          color: #c1c4ca;
        }

        :-ms-input-placeholder {
          color: #c1c4ca;
        }

        ::placeholder {
          color: #c1c4ca;
        }
      }

      .form-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px 0;
        @media (max-width: 538px) {
          display: block;
        }

        &.check {
          display: flex !important;
          justify-content: flex-start;
          color: #565869;
          font-size: 19px;
          @media (max-width: 538px) {
            font-size: 14px;
            line-height: 20px;
          }

          input {
            margin-right: 6px;
            cursor: pointer;
            &:active {
              opacity: 0.7;
            }
          }

          input[type="checkbox"] {
            background-color: #fff;
            font: inherit;
            color: grey;
            width: 19px;
            min-width: 19px;
            height: 19px;
            border: 2px solid #c1c4ca;
            border-radius: 4px;
            transform: translateY(-0.075em);
            display: grid;
            place-content: center;

            &:checked:after {
              content: "✔";
              font-size: 13px;
              text-align: center;
              width: 20px;
              min-width: 20px;
              height: 20px;
              border-radius: 4px;
              color: white;
              background: #f26722 !important;
            }
          }

          a {
            text-decoration: underline;
          }
        }
        .form-item {
          width: 48%;
          margin-bottom: 30px;
          @media (max-width: 538px) {
            width: 100%;
            margin-bottom: 20px;
          }

          &.full {
            width: 100%;
          }

          label {
            display: block;
            font-size: 19px;
            font-weight: 600;
            line-height: 40px;
            color: #565869;
            @media (max-width: 538px) {
              font-size: 16px;
            }
          }

          input {
            display: block;
            @media (max-width: 768px) {
              width: 80%;
            }
            width: 85%;
            // width: calc(100% - 40px);
            padding: 15px 25px;
            border-color: #c1c4ca;
            border-radius: 8px;
            border: 1px solid #c1c4ca;
            font-size: 16px;
            font-weight: 400;
          }

          textarea {
            @media (max-width: 768px) {
              width: 80%;
            }
            width: 90%;
            padding: 15px 25px;
            border-color: #c1c4ca;
            border-radius: 8px;
            border: 1px solid #c1c4ca;
            min-height: 200px;
            font-size: 16px;
            font-family: Arial, Helvetica, sans-serif;
          }
        }
      }

      p {
        color: rgb(228, 22, 22);
        font-size: 13px;
        font-weight: 500;
        margin-top: 5px;
      }

      .button {
        margin-top: 60px;
        width: 100%;
        margin-bottom: 80px;
      }
    }
  }
}

.is-loading {
  min-height: 400px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.c-u-completed {
  margin: 80px 0;
  text-align: center;

  .c-title {
    font-size: 35px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .c-desc {
    max-width: 450px;
    margin: 0 auto 80px auto;
    color: gray;
  }
}
