.schedule-demo {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-loading {
  font-size: 25px;
  font-weight: 600;
  position: absolute;
  text-align: center;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 150px;
  z-index: 30;
}

.demo-container {
  width: 60vw;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  min-height: 100vh;
  @media (max-width: 768px) {
    min-height: 953px !important;
    width: 95vw;
  }
}

.iframe {
  border: 0px !important;
}
