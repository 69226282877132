.section-container {
  background-color: white;
  margin: 2rem;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding: 2.5rem;
  display: flex;
  justify-content: space-between;
  .contact-form {
    width: 60%;
    margin-right: 6rem;
  }
  .contact-information {
    width: 40%;
    .contact-information-wrapper {
      margin-top: 80px;
      .header-text {
        font-family: Inter;
        font-size: 40px;
        font-weight: 400;
        line-height: 56px;
        text-align: left;
        color: #0e0e0e;
      }
      .content-text {
        font-family: Inter;
        font-size: 24px;
        font-weight: 400;
        line-height: 33px;
        text-align: left;
        color: #616161;
        span {
          color: #f26722;
        }
      }
    }
    .contact-info-container {
      .contact-info {
        display: flex;
        align-items: flex-start;
        margin-top: -20px;
      }
      .info-icon {
        margin-top: 24px;
        margin-right: 30px;
      }
      .info-title {
        font-family: Inter;
        font-size: 25px;
        font-weight: 300;
        text-align: left;
        color: #616161;
      }
      .info-content {
        font-family: Inter;
        font-size: 25px;
        margin-top: -20px;
        font-weight: 700;
        text-align: left;
        color: #171717;
      }
    }
    .social-links {
      display: flex;
      flex-direction: row;
      margin-top: 8rem;

      .social-info {
        margin-right: 10px;
      }

      .social-icon {
        img {
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 1024px) {
    /* For mobile screens */
    flex-direction: column-reverse;
    margin: 0;
    padding: 1.5rem;
    .contact-form {
      width: 100%;
      margin-right: 0px;
    }
    .contact-information {
      width: 100%;
      .contact-information-wrapper {
        margin-top: 0px;
        .header-text {
          font-size: 1.5rem;
        }
        .content-text {
          font-size: 1.2rem;
          margin-top: -20px;
        }
      }
      .contact-info-container {
        .info-icon {
          margin-right: 15px;
        }
        .info-title,
        .info-content {
          font-size: 1rem;
        }
        .info-content {
          margin-top: -15px;
        }
      }
      .social-links {
        margin-top: 2rem;
      }
    }
  }
}
