@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@300;400;500;600&display=swap");

@font-face {
  font-family: "Gilroy";
  src: url("./assets/fonts/Gilroy-ExtraBold.otf") format("woff2");
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.desktop-max-width {
  max-width: 1500px;
  margin: 0 auto;
}

.desktop-max-width-smaller {
  max-width: 1000px;
  margin: 0 auto;
}

ul {
  padding-inline-start: unset;
}

* {
  -webkit-tap-highlight-color: transparent;
}
