.main {
  transform: scale(0.9);
  position: fixed;
  z-index: 450;
  bottom: 0;
  right: 0;
  width: 35%;
  height: 26rem;
  padding: 1.25rem 2rem;
  background: white;
  border-radius: 0.75rem;
  border-radius: 8px;
  color: white;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.15) 0%,
      rgba(255, 255, 255, 0.15) 100%
    ),
    rgba(3, 7, 41, 0.88);
  box-shadow:
    0px 8px 16px 0px rgba(3, 2, 7, 0.1),
    0px 0px 1px 0px rgba(3, 2, 7, 0.1);
  @media (max-width: 1024px) {
    width: 87%;
    height: 31rem;
  }
  // top
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    h2 {
      font-weight: 500;
      margin-bottom: 1rem;
      @media (max-width: 768px) {
        font-size: 1.2rem;
      }
    }
    .close-icon {
      cursor: pointer;
    }
  }
  // text
  p {
    margin-top: 0.5rem;
    font-size: 12px;
    @media (min-width: 1024px) {
      font-size: 13.5px;
    }
    font-weight: 300;
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
  }
  // buttons
  .buttons {
    width: 90%;
    bottom: 1rem;
    left: 5;
    right: 5;
    position: absolute;
    margin-top: 2rem;
    display: flex;
    column-gap: 1rem;
    align-items: center;
    justify-content: space-between;
    button {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
    .decline-button,
    .customise-button {
      cursor: pointer;
      border: unset;
      text-align: center;
      border-radius: 48px;
      transition: 0.5s all ease-in-out;
      font-size: 16px;
    }
    @media (max-width: 768px) {
      .decline-button,
      .customise-button {
        width: 45%;
        font-size: 13px;
      }
    }
    .decline-button {
      background-color: transparent;
      color: white;
      border: 1px solid white;
    }
  }
}
.toggler {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.875rem;
  @media (min-width: 1024px) {
    font-size: 1.25rem;
  }
  border-bottom: 0.3px solid white;
  padding-bottom: 0;
  height: 2rem;
  @media (max-width: 768px) {
    height: 1.5rem;
  }
  margin: 1.5rem 0;
}
.active {
  font-weight: 800;
  border-bottom: 5px solid #f26722;
}
h4 {
  cursor: pointer;
}
.buttons-inner {
  margin-top: 1.5rem;
  width: 100%;
  display: flex;
  column-gap: 0.75rem;
  row-gap: 0.5rem;
  @media (min-width: 1024px) {
    margin-top: 2rem;
    column-gap: 1.25rem;
  }
  @media (max-width: 1023px) {
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
