.content-container {
  display: flex;
  justify-content: center;
  margin: 5rem 10rem 0;

  .content-box {
    border: 1px solid #9898982b;
    border-radius: 20px;
    padding: 2rem;
    background-color: #c4c4c414;
    margin-right: 30px;
    cursor: pointer;

    .support-title {
      font-size: 20px;
      font-weight: 400;
      line-height: 25px;
      text-align: left;
      color: #ececec;
    }

    .support-content {
      font-family: Poppins;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #bdbdbd;
    }
  }

  .content-box:last-child {
    margin-right: 0;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    margin: 5rem 0;
    padding: 0 1rem;

    .content-box {
      margin-right: 0;
      margin-bottom: 30px;
      padding: 1rem;
      .support-title {
        font-size: 16px;
      }
      .support-content {
        font-size: 14px;
      }
    }
  }
}
