.App {
  overflow: hidden;
}

@media (max-width: 978px) {
  .App.no-overflow {
    overflow-y: hidden;
    max-height: 100vh;
  }
}

.no-overflow-2 {
  overflow-y: hidden;
  max-height: 100vh;
}

.click {
  cursor: pointer;
  &:active {
    opacity: 0.8;
  }
}

.no-select {
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
}
