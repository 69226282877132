.header {
  display: flex;
  justify-content: center;
  position: relative;

  .header-text {
    text-align: center;

    .main-text {
      color: #f5f5f5;
      font-size: 5rem;
      font-weight: 400;
    }

    .secondary-text {
      font-family: Poppins;
      font-size: 1.5rem;
      font-weight: 300;
      color: #bdbdbd;
      margin-top: -80px;
    }
  }

  @media screen and (max-width: 768px) {
    /* For mobile screens */
    .header-text {
      .main-text {
        font-size: 2.5rem;
      }
      .secondary-text {
        font-size: 1rem;
        margin-top: 20px;
        margin-top: -30px;
        margin-bottom: 50px;
      }
    }
  }
}
