.main-section {
  transform: scale(0.9);
  position: fixed;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  bottom: 0;
  color: #000;
  background-color: transparent;
  z-index: 1000;
  padding: 1rem 0;
  line-height: 1.5;
  section {
    background-color: white;
    width: 37%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 0.65rem;
    box-shadow:
      0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    padding: 1.25rem 1.85rem;
    margin-top: 1.25rem;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 0.4375rem;
      padding-top: 0.125rem;
      h3 {
        color: #000;
        font-weight: 600;
        font-size: 1.125rem;
      }

      @media (min-width: 768px) {
        h3 {
          font-size: 1.5rem;
        }
      }
    }
    .body {
      height: 18.5rem;
      font-weight: 300;
      font-size: 0.9rem;
      overflow-y: scroll;
      h4 {
        font-weight: 500;
        font-size: 1.05rem;
      }
      ul {
        list-style-type: disc;
        padding-left: 1.25rem;
        margin-top: 1rem;
      }
    }
    .footer {
      box-shadow: 0px -8px 12px -12px #0d0d0d1c;
      padding-top: 0.5rem;
      padding-bottom: 2.5rem;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background: white;
      .check-container {
        margin-top: 1rem;
        margin-bottom: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .check-container {
        margin-bottom: 2rem;
      }
      span {
        color: #5d5e68;
        font-weight: 500;
      }

      @media (max-width: 640px) {
        .buttons {
          margin-top: 2rem;
          gap: 1.25rem;
          flex-wrap: wrap;
          display: flex;
          justify-content: center;
          .secondaryBtn,
          .primaryBtn {
            width: 33.3%;
            font-size: 12px;
          }
        }
      }
    }

    @media (min-width: 768px) {
      .body {
        height: 25rem;
      }
    }
  }
}

@media (max-width: 640px) {
  .main-section {
    font-size: 14px;
    section {
      width: 80%;
      .footer {
        padding-bottom: 0rem;
      }
    }
  }
}
.secondaryBtn {
  color: #f26722;
  background-color: #fef0e9;
  cursor: pointer;
  padding: 0.85rem;
  text-align: center;
  padding: 1.1rem 0.5rem;
  border-radius: 2.5rem;
  display: flex;
  justify-content: center;
  font-size: 0.875rem;
  width: 27.5%;
}
.decline-all {
  border: 1px solid #f26722;
  color: #f26722;
  background: transparent;
}
@media (max-width: 640px) {
  .secondaryBtn {
    width: 100%;
  }
}
.primaryBtn {
  color: #fff;
  background-color: #f26722;
  padding: 0.85rem;
  text-align: center;
  padding: 1.1rem 0.5rem;
  border-radius: 2.5rem;
  font-size: 0.875rem;
  width: 27.5%;
  font-weight: 600;
  cursor: pointer;
}

@media (max-width: 640px) {
  .primaryBtn {
    width: 100%;
  }
}
.cookie-content {
  font-size: 12px;
  display: block !important;
  flex-direction: row !important;
}
.cookie-content-span {
  color: #f26722;
  font-weight: 400;
  cursor: pointer;
}
/* Style to make the scrollbar invisible */
div::-webkit-scrollbar {
  width: 12px;
}

div::-webkit-scrollbar-track {
  background: transparent;
}

div::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: none;
}
/* Hidden default checkbox */
input[type="checkbox"] {
  display: none;
}

/* Custom checkbox container */
.checkbox-container {
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 20px;
  background-color: #ddd;
  cursor: pointer;
}
/* Ball inside the checkbox */
.ball {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  transition: transform 0.3s ease-in-out;
}

/* Styling when the checkbox is checked */
input[type="checkbox"]:checked + .ball {
  transform: translateX(100%);
  background-color: #f26722;
}
.cancel-icon {
  cursor: pointer;
}
.text-primary {
  color: #f26722;
  text-decoration: underline;
  margin: 0 0.21rem;
}
