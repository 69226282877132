.support-header {
  display: flex;
  justify-content: center;
  position: relative;

  .header-text {
    margin-top: 4rem;
    text-align: center;
    .rect-image {
      width: 251.61px;
      height: 447.42px;
      background: linear-gradient(
        98.24deg,
        rgba(109, 220, 255, 0.14) 0%,
        rgba(127, 96, 249, 0.14) 100%
      );
      filter: blur(105px);
      transform: rotate(-83.51deg);
      position: absolute;
      top: 10%;
      right: 42%;
    }
    .main-text {
      color: #f5f5f5;
      font-size: 5rem;
      font-weight: 400;
    }

    .secondary-text {
      font-family: Poppins;
      font-size: 1.5rem;
      font-weight: 300;
      color: #bdbdbd;
      margin-top: -60px;
    }
  }

  @media screen and (max-width: 768px) {
    /* For mobile screens */
    .header-text {
      .rect-image {
        left: 40%;
        top: 4%;
        height: 300px;
      }
      .main-text {
        font-size: 2.5rem;
      }
      .secondary-text {
        font-size: 1rem;
        margin-top: 20px;
      }
    }
  }
}
