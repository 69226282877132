.banner {
  position: relative;
  background-repeat: no-repeat;
  background-image: url("../../../assets/svg/banner-bg.svg");
  background-color: #030729;
  background-position: right;
  background-size: contain;

  @media (max-width: 768px) {
    background-position: 80px 30px;
  }

  .banner-wrapper {
    padding: 0px 40px;
    margin: 0 auto;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 650px;
    max-height: 1300px;
    height: calc(100vh - 94px);

    @media (max-width: 978px) {
      height: calc(100vh - 82px);
    }

    @media (max-width: 768px) {
      padding: 0px 20px;
    }

    .b-row-one {
      margin-top: -100px;
      font-weight: 800;
      font-size: 60px;
      margin-bottom: 48px;
      font-family: "Raleway";

      @media (max-width: 768px) {
        font-size: 37px;
        margin-bottom: 30px;
      }
      span {
        color: #f26722;
      }
    }

    .b-row-two {
      color: #e1e1e1;
      font-weight: 300;
      font-size: 22px;
      line-height: 33px;
      max-width: 728px;
      margin-bottom: 70px;

      @media (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 40px;
        max-width: 400px;
      }
    }

    .b-row-three {
      display: flex;
      flex-direction: row;
      @media (max-width: 350px) {
        flex-direction: column;
      }

      .button {
        @media (max-width: 768px) {
          font-size: 12px !important;
        }

        &.primary {
          padding: 22px 32.5px;
          margin-right: 17px;
          min-width: 192px;
          @media (max-width: 768px) {
            padding: 13.5px 16px;
            min-width: 148px;
          }
          @media (max-width: 350px) {
            margin-right: unset;
            margin-bottom: 15px;
          }
        }

        &.custom {
          padding: 9px 25px;
          display: flex;
          align-items: center;
          color: white;
          background: rgba(255, 255, 255, 0.15);
          padding: 22px 35px;
          @media (max-width: 768px) {
            padding: 14px 16px;
          }

          .play-icon {
            margin-right: 10px;
            height: 20px;
            width: 20px;
            @media (max-width: 768px) {
              height: 12px;
              width: 12px;
            }
          }
        }
      }
    }
  }
}
