.button {
  width: fit-content;
  font-size: 16px;
  font-family: "Plus Jakarta Sans";
  cursor: pointer;
  border: unset;
  text-align: center;
  border-radius: 48px;
  transition: 0.5s all ease-in-out;

  &.primary {
    color: white;
    background-color: #f26722;
    padding: 13px 25px;
    box-shadow: 0px 8px 20px rgba(242, 103, 34, 0.26);
  }

  &.secondary {
    color: #f26722;
    background-color: transparent;
    border: 1.5px solid #f26722;
    padding: 11.5px 25px;
  }

  &.text {
    padding: 10px 0;
    background-color: transparent;
    font-size: 16px;
    color: #f26722;
  }

  &:active {
    opacity: 0.8;
  }

  &:hover {
    transform: scale(1.05);
  }
  @media (max-width: 768px) {
    font-size: 13px;
  }
}
